<template lang="pug">
     v-container

        v-card.pointer(v-for="category in categories" :key="category.id" class="mx-auto mb-1" max-width="400" @click.native="goToCategory(category)")
            v-card-title {{ category.name }}

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}

</template>


<script>
    import { tradepointService, categoryService } from '@/_services'
    import { mapState, mapMutations } from "vuex";

    export default {
        data: () => ({
            loading:false,
            error:null,
            categories:[]
        }),

        mounted() {
            if (!this.selectedPoint) this.loadTradePointData()
            else {
                this.setAppBarTitle(this.selectedPoint.address)
            }
            this.loadCategories()
        },
        destroyed() {
            this.setSelectedPoint(null)
        },

        computed: {
            ...mapState(['appBarTitle']),
            ...mapState("tradepoint", ["selectedPoint"])
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("tradepoint", ["setSelectedPoint"]),
            ...mapMutations("category", ["setSelectedCategory"]),

            loadTradePointData(){
                this.setAppBarTitle('loading')
                tradepointService.loadPoint(this.$route.params.pid)
                    .then(data => {
                        this.setSelectedPoint(data)
                        this.setAppBarTitle(data.address)
                    })
                    .catch(error => {
                        this.setSelectedPoint(null)
                        this.setAppBarTitle(error)
                    })
            },

            loadCategories(){
                this.loading = true
                categoryService.getpointcategories(this.$route.params.pid)
                    .then(data => {
                        this.categories = data
                        this.loading = false
                    })
                    .catch(error => {
                        this.error = error
                        this.loading = false
                    })
            },

            goToCategory(c){
                this.setSelectedCategory(c)
                this.$router.push(`/project/${this.$route.params.id}/network/${this.$route.params.nid}/tradepoint/${this.$route.params.pid}/category/${c.id}`)
            }
            
        }
    }
</script>